.Preface {
    padding-left: 1%;
}

.Preface > div > p {
    font-size: 24px;
}

.Preface button {
    cursor: pointer;
}
