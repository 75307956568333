.PublicationTerms {
    padding-left: 1%;
}

.PublicationTerms > div > p {
    font-size: 24px;
}

.PublicationTerms button {
    cursor: pointer;
}
