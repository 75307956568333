html {
    width: 100%;
    height: 100%;
}

body {
    height: 100%;
    width: 100%;
    margin: 0;
}

body {
    background: #C0C0C0 url('../../../assets/img/mainPageBackground.png') no-repeat center center;
}

.Sidebar {
    position: fixed;
    width: 16%;
    height: 100%;
    border-right: 1px solid black;
}

.Sidebar ol {
    list-style-type: none;
    position: absolute;
    bottom: 5%;
}

.Sidebar li {
    margin-bottom: 2px;
}

.Title {
    padding-left: 5%;
    text-align: center;
    color: #D91809;
    font-family: Book Antiqua, serif;
    font-size: 32px;
    font-weight: normal;
    margin-top: 0;
    padding-top: 1%;
}

.SubTitle {
    text-align: center;
    margin-left: 5%;
    font-size: 24px;
    font-weight: normal;
}

.LanguagesAndClockWrapper {
    position: absolute;
    right: 2%;
    top: 2%;
}

.LanguagesWrapper {
    position: absolute;
    left: 15%;
    cursor: pointer;
}

.ClockWrapper * {
    border: none;
}

.ArticlesTitle {
    text-decoration: underline;
    font-size: 16px;
    font-weight: normal;
}

.ArticlesWrapper {
    margin-top: 2%;
    margin-left: 17%;
}

.FilenamesList li {
    margin-bottom: 3px;
}
